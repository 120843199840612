.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's on top of other content */
}

.modal-content {
	background: white; /* White background for the modal */
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	width: 300px; /* Adjust as needed */
}

.close-button {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	margin-bottom: 10px;
}
